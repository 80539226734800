import { LogEvent } from '@analytics';
import { motion } from 'framer-motion';
import { distanceBetween } from 'geofire-common';
import Image from 'next/image';
import Link from 'next/link';
import { cn } from 'tailwind-config';
import { useLocalStorage } from 'usehooks-ts';

import { Lottie } from '@components';
import { 면허보유여부 } from '@pages/personal/funnel';

interface SliderContentProps {
  body: string;
  src: string;
  url: string;
}

const ReLicenseCTAContents: SliderContentProps[] = [
  {
    body: '빠르게\n면허 재취득하기',
    src: '/re-license/cta.png',
    url: '/re-license',
  },
  {
    body: '전국 운전학원\n예약하기',
    src: '/location-lottie.json',
    url: '/explore',
  },
];
const CTAContents: SliderContentProps[] = [
  {
    body: '3일 만에\n면허 취득하기',
    src: '/fast-license/thunder.png',
    url: '/fast-license',
  },
  {
    body: '전국 운전학원\n예약하기',
    src: '/location-lottie.json',
    url: '/explore',
  },
];

export const HomeCTA = () => {
  const [{ registerType, location }] = useLocalStorage(
    'user-info',
    {
      registerType: '',
      location: null,
    } as {
      registerType: 면허보유여부 | '';
      location: ExploreSearchData | null;
    },
    {
      initializeWithValue: false,
    },
  );

  return (
    <div className="flex justify-between gap-10 px-16 pt-16">
      {(registerType === '면허취소자' &&
      location &&
      distanceBetween(
        [37.798549752152, 126.994550606356],
        [location.latitude, location.longitude],
      ) < 50
        ? ReLicenseCTAContents
        : CTAContents
      ).map((content, index) => {
        const { body, src, url } = content;
        return (
          <motion.div
            whileTap={{ scale: 0.97 }}
            key={index}
            className={cn('text-new-Section-Title bg-new-white flex-1 rounded-[16px] p-20 pb-16')}
          >
            <Link
              key={index}
              onClick={() => {
                LogEvent.활성화.homeCTA('홈 CTA 버튼 : ' + url);
                LogEvent.활성화.homeMainCTA(url);
              }}
              href={url}
            >
              <div className="flex flex-col">
                <div className="flex items-start justify-between">
                  <div className="text-new-Section-Title break-keep">
                    {body.split('\n').map((text, index) => (
                      <span key={'span-' + index}>
                        {text}
                        <br />
                      </span>
                    ))}
                  </div>
                </div>
                <div className="self-end">
                  {src.includes('.json') ? (
                    <Lottie
                      path={src}
                      size={{
                        width: 64,
                        height: 64,
                      }}
                    />
                  ) : (
                    <Image
                      src={src}
                      alt={body}
                      width={150}
                      height={150}
                      className="h-[64px] w-[64px]"
                    />
                  )}
                </div>
              </div>
            </Link>
          </motion.div>
        );
      })}
    </div>
  );
};
